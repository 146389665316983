import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.Dark.css';
import ListCategories from './Components/ListCategories';

function App() {
  return (
    <div className="App">
        <h2 className="App-title">My market list</h2>
        <ListCategories />
    </div>
  );
}

export default App;
