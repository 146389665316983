import React, { Component } from 'react';
import { Tab, Row, Col, Nav, Button, ButtonGroup } from 'react-bootstrap';
import Items from './Items';
import { database } from '../Data/fire';
import formatMoney from "../Data/utils";
import AddItem from './AddItem';

export default class ListCategories extends Component {

    state = {
        data: [],
        editForm: {
            id: '',
            priceControl: 0,
            articleControl: '',
            categoriesControl: ''
        }
    }

    componentDidMount() {

        this.itemsRef = database.ref('items').orderByKey().limitToLast(100);
        this.itemsRef.on('child_added', snapshot => {
            // Update React state when message is added at Firebase Database 
            let user = { text: snapshot.val(), id: snapshot.key };
            /* console.log('user', user); */
            this.setState(state => {
                const data = state.data.concat(user);
                return {
                    data
                };
            });
        });
        this.itemsRef.on('child_changed', data => {
            /* console.log('child_changed', data.key, data.val()); */
            var newItem = data.val();
            this.setState(prevState => ({
                data: prevState.data.map(item => item.id !== data.key ? item : {
                    ...item, text: {
                        ...item.text,
                        name: newItem.name,
                        category: newItem.category,
                        price: newItem.price,
                        state: newItem.state
                    }
                })
            }));
        });

        this.itemsRef.on('child_removed', data => {
            console.log('child_removed', data.key);
        });
    }

    handleItemClick = (itemId) => {

        let item = this.state.data.filter(i => i.id === itemId)[0].text;
        item.state = !item.state;

        let itemRef = database.ref('items/' + itemId);
        itemRef.set(item);
    }

    handleItemUpdateClick = (itemId) => {
        let item = this.state.data.filter(i => i.id === itemId)[0].text;
        this.setState({
            editForm: {
                categoriesControl: item.category,
                articleControl: item.name,
                priceControl: item.price,
                id: itemId
            },
            showModal: true
        });
    }

    getTotal = () => {
        let total = this.state.data.filter(i => i.text.state).reduce((a, b) => a + b.text.price, 0);
        return formatMoney(total, 0);
    }

    openAddItems = () => this.setState({ showModal: true })

    handleCloseWindow = (item, id) => {
        this.setState({
            showModal: false, editForm: {
                id: '',
                priceControl: 0,
                articleControl: '',
                categoriesControl: ''
            }
        });
        if (item === undefined)
            return;

        if (id) {
            let currentItem = this.state.data.filter(i => i.id === id)[0].text;
            currentItem.category = item.category;
            currentItem.name = item.name;
            currentItem.price = item.price;

            let itemRef = database.ref('items/' + id);
            itemRef.set(currentItem);

        } else {
            const newId = (this.state.data.length > 0 ? this.state.data.reduce(
                (max, item) => (parseInt(item.id) > max ? parseInt(item.id) : max),
                this.state.data[0].id
            ) : 0) + 1;
            let itemRef = database.ref('items/' + newId);
            itemRef.set(item);
        }
    }

    getCategories = () => {

        let categories = [];
        this.state.data.forEach(element => {
            let existCategory = categories.find(i => i === element.text.category);
            if (!existCategory) {
                categories.push(element.text.category);
            }
        });
        return categories;
    }

    render() {
        let categories = this.getCategories();

        if (categories.length === 0)
            return (<p>Loading...</p>)
        return (
            <div>
                <AddItem showModal={this.state.showModal} categories={categories}
                    preCategory={this.state.selectedCategory}
                    editMode={this.state.editForm}
                    closeWindow={this.handleCloseWindow} />
                {/* <div className="total-label">
                    <p>Total: {this.getTotal()}</p>
                </div> */}
                {/* <div className="total-label">
                    <ButtonGroup size="sm">
                        <Button variant="dark">Left</Button>
                        <Button variant="dark">Middle</Button>
                        <Button variant="dark">Right</Button>
                    </ButtonGroup>
                </div> */}
                <Tab.Container id="left-tabs-categories-container" defaultActiveKey="first">
                    <div className="add-item">
                        <Button variant="link" onClick={this.openAddItems}>+</Button>
                    </div>
                    <Row className="main-placeholder">
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column"
                                onSelect={(selectedCategory) => this.setState({ selectedCategory })}>
                                {
                                    categories.map(category => (
                                        <Nav.Item key={category}>
                                            <Nav.Link eventKey={category}>{category}</Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>

                                {
                                    this.state.data.map(item => {
                                        return (
                                            <Tab.Pane eventKey={item.text.category} key={item.id}>
                                                <Items id={item.id}
                                                    name={item.text.name}
                                                    state={item.text.state}
                                                    price={item.text.price}
                                                    handleClick={(i) => this.handleItemClick(item.id)}
                                                    handleUpdateClick={(i => this.handleItemUpdateClick(item.id))} />
                                            </Tab.Pane>
                                        )
                                    })
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <footer className="footer">
                    <h4>Total: {this.getTotal()}</h4>
                </footer>

            </div>
        )
    }
}
