import React, { Component } from 'react'
import { InputGroup, Form, Col, Row } from 'react-bootstrap'

export default class Items extends Component {

    handleClick = (event) => {
        event.preventDefault();
        console.log(event.target.id);
        this.props.handleClick(event.target.id);
    }

    render() {
        /* console.log('this.props', this.props); */
        return (
            <div className="item-container">

                <Row>
                    <Col>
                        <InputGroup className="mb-3" key={this.props.id}>
                            <Form.Check type='checkbox' id={this.props.id}>
                                <Form.Check.Input type='checkbox' isValid={this.props.state} isInvalid={!this.props.state}
                                    checked={this.props.state}
                                    onChange={this.handleClick} id={this.props.id} />
                                <Form.Check.Label
                                    onClick={this.handleClick} id={this.props.id} >{this.props.name}</Form.Check.Label>

                                {/* <Form.Control.Feedback type={item.state ? 'valid' : 'invalid'}>{item.state ? 'Seleccionado' : 'Pendiente'}</Form.Control.Feedback> */}
                            </Form.Check>

                        </InputGroup>

                    </Col>

                    <Col xs lg="2">
                        {this.props.price}
                        <div onClick={() => this.props.handleUpdateClick()} id={this.props.id} className="item-edit-icon">
                            <svg className="bi bi-pencil" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l2 2a1 1 0 010 1.414l-9 9a1 1 0 01-.39.242l-3 1a1 1 0 01-1.266-1.265l1-3a1 1 0 01.242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z" clipRule="evenodd" />
                                <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 00.5.5H4v.5a.5.5 0 00.5.5H5v.5a.5.5 0 00.5.5H6v-1.5a.5.5 0 00-.5-.5H5v-.5a.5.5 0 00-.5-.5H3z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </Col>
                </Row>


            </div>
        )
    }
}

/*

<Form>
  {['checkbox', 'radio'].map((type) => (
    <div key={type} className="mb-3">
      <Form.Check type={type} id={`check-api-${type}`}>
        <Form.Check.Input type={type} isValid />
        <Form.Check.Label>{`Custom api ${type}`}</Form.Check.Label>
        <Form.Control.Feedback type="valid">You did it!</Form.Control.Feedback>
      </Form.Check>
    </div>
  ))}
</Form>
*/