import * as firebase from 'firebase';

 const firebaseConfig = {
    apiKey: "AIzaSyDb9GlcT2sumJLa7VsGCbVX0r9639UDzsQ",
    authDomain: "market-list-b0e29.firebaseapp.com",
    databaseURL: "https://market-list-b0e29.firebaseio.com",
    projectId: "market-list-b0e29",
    storageBucket: "market-list-b0e29.appspot.com",
    messagingSenderId: "1046245308056",
    appId: "1:1046245308056:web:93285de95d5718e1c902ed",
    measurementId: "G-SYS9QY6JXD"
 };

 firebase.initializeApp(firebaseConfig);
 const database = firebase.database();

 export {
     database,
 };