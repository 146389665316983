import React, { Component } from 'react'
import { Modal, Form, Button } from 'react-bootstrap';

export default class AddItem extends Component {

    state = {
        form: {
            id: '',
            priceControl: 0,
            articleControl: '',
            categoriesControl: ''
        },
        selectedCategory: ''
    }

    handleClose = () => this.props.closeWindow()

    componentDidUpdate(prevProps) {
        if (this.props.preCategory !== prevProps.preCategory) {
            this.setState({
                form: {
                    ...this.state.form,
                    categoriesControl: this.props.preCategory
                }
            });
        }
        if (this.props.editMode !== prevProps.editMode) {
            this.setState({
                form: {
                    ...this.state.form,
                    categoriesControl: this.props.editMode.categoriesControl,
                    articleControl: this.props.editMode.articleControl,
                    priceControl: this.props.editMode.priceControl,
                    id: this.props.editMode.id
                },
                showModal: true
            });
        }
    }

    handleCategoriesControlChange = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                categoriesControl: event.target.value
            }
        });
    }

    handleArticleControlChange = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                articleControl: event.target.value
            }
        });
    }

    handlePriceControlChange = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                priceControl: event.target.value
            }
        });
    }

    saveItem = (event) => {
        if (event) {
            event.preventDefault();
        }

        if (this.state.form.categoriesControl && this.state.form.articleControl) {
            let item = {
                category: this.state.form.categoriesControl,
                name: this.state.form.articleControl,
                price: parseInt(this.state.form.priceControl),
                state: false
            };

            if (this.state.form.id) {
                this.props.closeWindow(item, this.state.form.id);
            } else {
                this.props.closeWindow(item);
            }

            this.setState({
                form: { categoriesControl: '', articleControl: '', priceControl: '0' }
            });
        }
    }

    render() {
        return (
            <div>
                <Modal show={this.props.showModal} onHide={() => this.handleClose(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.form.id ? `Actualizar Artículo ${this.state.form.id}` : 'Agregar Artículo'} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.saveItem} >
                            <Form.Group controlId="categoryControl">
                                <Form.Label>Categorias</Form.Label>
                                <Form.Control as="select" value={this.state.form.categoriesControl}
                                    onChange={this.handleCategoriesControlChange} >
                                    <option></option>
                                    {
                                        this.props.categories.map(i => <option key={i} >{i}</option>)
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="nameControl">
                                <Form.Label>Articulo</Form.Label>
                                <Form.Control type="text"
                                    placeholder="Articulo"
                                    value={this.state.form.articleControl}
                                    onChange={this.handleArticleControlChange} />
                            </Form.Group>
                            <Form.Group controlId="priceControl">
                                <Form.Label>Precio</Form.Label>
                                <Form.Control type="text"
                                    placeholder="1500"
                                    value={this.state.form.priceControl}
                                    onChange={this.handlePriceControlChange} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleClose(false)}>
                            Close
                         </Button>
                        <Button variant="primary" onClick={() => this.saveItem()}>
                            Guardar Cambios
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
